import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import comingSoon from "./assets/img/coming_soon.jpg";
import "./App.css";
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="wrapper">
      <div className="img-lg">
        <img src={comingSoon} alt="coming_soon" />
      </div>
      <h1 className="text-white">Coming Soon !!!</h1>
      <Analytics/>
    </div>
  );
}

export default App;
